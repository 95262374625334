import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'ngx-common-solution';
import { environment } from 'src/environments/environment';
import { NotificationGetDto } from 'src/dto/GetDtos/notification-get-dto';
import { NotificationPutDto } from 'src/dto/PutDtos/notification-put-dto';
import { NotificationPostDto } from 'src/dto/PostDtos/notification-post-dto';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService<
  NotificationGetDto,
  NotificationPutDto,
  NotificationPostDto
> {
  constructor(http: HttpClient) {
    super(environment.apiBaseUrl, http, 'notification');
  }
  markNotificationAsRead(notificationId: number) {
    return this.http.put(
      `${environment.apiBaseUrl}${this.path}/${notificationId}/read`,
      {},
    );
  }
}
